import React from 'react'

import { Router } from "@reach/router"

import PageGoalPreview from 'components/pageDataPreview/PageGoalPreview'

const GoalPreview = () => (
  <Router basepath={`/ru/goal_preview`}>
    <PageGoalPreview path={'/:slug'} lang={'ru'} />
  </Router>
)

export default GoalPreview
